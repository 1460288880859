import type { Contexts, Primitive } from '@sentry/types';
import type { ServiceErrorData } from '@root/common/types/service';

// TODO (types): Create common type for all apis
type ApiType = 'unknown' | 'content' | 'media' | 'config' | 'custom-page';

interface DefaultErrorTags {
  processType: 'server' | 'client';
}

export interface ServiceErrorTags {
  apiType: ApiType;
  responseCode: number;
}

export interface ComponentErrorTags {
  componentName: string;
}

export type ErrorTags<T> = T;

export interface ContextInner {
  data: Record<string, unknown>;
  cause: {
    reason: Primitive;
  };
}

export interface SentryData {
  contexts: Contexts;
  tags: Record<string, Primitive>;
}

export interface ErrorContext<T extends ContextInner> extends Contexts {
  data: T['data'];
  cause: T['cause'];
}

export class SentryError<CTX extends ContextInner, TAG extends ServiceErrorTags | ComponentErrorTags> extends Error {
  // Additional data to be sent to Sentry that is grouped under specific error
  public contexts: ErrorContext<CTX> = {
    cause: {
      reason: 'Error with unspecified reason' as CTX['cause']['reason'],
    },
    data: {} as CTX['data'],
  };
  // Used to filter errors in Sentry
  public tags = {
    processType: process.server ? 'server' : 'client',
  } as ErrorTags<TAG> & DefaultErrorTags;
  // Error message to be shown in the client for the user
  public clientMessage = 'error.unknown';
  // Check if error is synchronized with service error data
  private syncedError = false;

  constructor(message: string) {
    super(message);
    this.message = message;
  }

  /**
   * Set initial contexts for page level error.
   * Use this data to for additional information about error inside Sentry admin
   */
  protected setInitialContexts(contexts: ErrorContext<CTX>): void {
    this.contexts = { ...this.contexts, ...contexts };
  }

  /**
   * Set initial tags for page level error.
   * Use this data to filter errors inside Sentry admin
   */
  protected setInitialTags(tags: TAG): void {
    this.tags = { ...this.tags, ...tags };
  }

  /**
   * Synchronize SentryError with ServiceErrorData
   */
  public sync(serviceErrorData: ServiceErrorData | null): this {
    if (!serviceErrorData) {
      return this;
    }

    if (serviceErrorData.message) {
      this.clientMessage = serviceErrorData.message;
    }

    const serviceTags = this.tags as ServiceErrorTags;

    if (serviceTags.responseCode && serviceErrorData.statusCode) {
      serviceTags.responseCode = serviceErrorData.statusCode;
    }

    // Set error as synchronized with service error data
    this.syncedError = true;

    return this;
  }

  /**
   * Set response code for error
   * @param code - Response code to be set
   * @param isError - Check if error condition is met (default true)
   */
  public responseCode(code: number, isError = true): void {
    // Prefer synchronized service error data over custom 404 error
    if (this.syncedError || !isError) {
      return;
    }

    const serviceTags = this.tags as ServiceErrorTags;

    if (serviceTags.responseCode) {
      serviceTags.responseCode = code;
    }
  }

  /**
   * Serialize Sentry data for extra information for Sentry to parse errors
   */
  public serializeSentryData(): SentryData {
    return {
      contexts: this.contexts as Contexts,
      tags: this.tags as unknown as Record<string, Primitive>,
    };
  }
}
